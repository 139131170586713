import React from 'react'

function First() {
  return (
    <>
        <section className="project-detail">
            <div className="container">
                <div className="upper-box">
                <div className="single-item-carousel owl-carousel owl-theme">
                    <figure className="image">
                        <img src="assets/img/gallery/protfolio-img01.jpg" alt="img" />
                    </figure>
                </div>
                </div>
                
                <div className="lower-content2">
                <div className="row">
                    <div className="text-column col-lg-9 col-md-12 col-sm-12">
                    <div className="s-about-content wow fadeInRight" data-animation="fadeInRight" data-delay=".2s" >
                        <p>
                        The world of search engine optimization is complex and
                        ever-changing, but you can easily understand the basics, and even
                        a small amount of SEO knowledge can make a big difference. Free
                        SEO education is also widely available on the web, including in
                        guides like this! (Woohoo!) This guide is designed to describe all
                        major aspects of SEO, from finding the terms and phrases
                        (keywords) that can generate qualified traffic to your website, to
                        making your site friendly to search engines, to building links and
                        marketing the unique value of your site.Etiam pharetra erat sed
                        fermentum feugiat velit mauris egestas quam ut erat justo.
                        </p>
                        <p>
                        Fusce eleifend donec sapien sed phase lusa pellentesque
                        lacus.Vivamus lorem arcu semper duiac. Cras ornare arcu avamus nda
                        leo Etiam ind arcu. Morbi justo mauris tempus pharetra interdum at
                        congue semper purus. Lorem ipsum dolor sit.The world of search
                        engine optimization is complex and ever-changing, but you can
                        easily understand the basics.
                        </p>
                        <div className="two-column mt-30 mb-30">
                        <div className="row aling-items-center">
                            <div className="image-column col-xl-4 col-lg-12 col-md-12">
                                <img src="assets/img/gallery/protfolio-img02.jpg" alt="img" />
                            </div>
                            <div className="text-column col-xl-4 col-lg-12 col-md-12 text-right">
                                <img src="assets/img/gallery/protfolio-img03.jpg" alt="img" />
                            </div>
                            <div className="text-column col-xl-4 col-lg-12 col-md-12 text-right">
                                <img src="assets/img/gallery/protfolio-img04.jpg" alt="img" />
                            </div>
                        </div>
                        </div>
                        <p>
                        Lorem ipsum is simply free text used by copytyping refreshing.
                        Neque porro est qui dolorem ipsum quia quaed inventore veritatis
                        et quasi architecto beatae vitae dicta sunt explicabo. Aelltes
                        port lacus quis enim var sed efficitur turpis gilla sed sit amet
                        finibus eros. Lorem Ipsum is simply dummy text of the printing.
                        </p>
                    </div>
                    </div>
                    <div className="info-column col-lg-3 col-md-12 col-sm-12">
                    <div className="inner-column3">
                        <h3>Project Info</h3>
                        <ul className="project-info clearfix">
                        <li>
                            <strong>Client:</strong> <span>Rosalina William</span>
                        </li>
                        <li>
                            <strong>Date:</strong> <span>SEO, Marketing, SMM</span>
                        </li>
                        <li>
                            <strong>Category: </strong> <span>1th Dec 2022</span>
                        </li>
                        <li>
                            <div className="footer-social mt-10">
                            <a href="#">
                                <i className="fab fa-facebook-f" />
                            </a>
                            <a href="#">
                                <i className="fab fa-instagram" />
                            </a>
                            <a href="#">
                                <i className="fab fa-twitter" />
                            </a>
                            </div>
                        </li>
                        </ul>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default First