import React  from 'react'
import { Link } from 'react-router-dom';
import Feedings from '../../assets/images/COSMOPOLITAN UNIVERSITY 075.jpg'
import Bredcom from "../../pages/Bredcom/bredcom";
import {
    projectFacebook,
    projectInstagram,
    projectLinkedIn,
    projectTwitter,
    projectYoutube
} from "../../resources/constants";
function Feeding() {
    return (
        <>
            <Bredcom
                title="Home"
                subtitle="Feeding"/>

            <section className="inner-blog pt-120 pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="bsingle__post mb-50">
                                <div className="bsingle__post-thumb">
                                    <img src={Feedings} alt="" />
                                </div>
                                <div className="bsingle__content">
                                    <h3>
                                        Feeding at Cosmopolitan University
                                    </h3>
                                    <div style={{textAlign: "justify"}}>
                                        <p>At Cosmopolitan University, The meals arrangement is solely-based on the students’ preference. The school however has made a provision for students and parents that want to regulate the feeding cost in an effective manner. Students can pay a certain amount (there are no limitations) and be given a feeding booklet. Using the slips in this booklet, they can buy food from the eating areas that use this method as a form of payment. This reduces the need to carry around cash whilst ensuring that students are properly fed and satisfied. Currently, the only cafeterias that use this form of transaction is Brim and Terminal 2
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-4">
                            <aside className="sidebar-widget">
                                <section id="custom_html-5" className="widget_text widget widget_custom_html" >
                                    <h2 className="widget-title">Follow Us</h2>
                                    <div className="textwidget custom-html-widget">
                                        <div className="widget-social">
                                            <a href={projectTwitter} title="Twitter" target="_blank">
                                                <i className="fab fa-twitter" />
                                            </a>
                                            <a href={projectYoutube} title="Youtube" target="_blank">
                                                <i className="fab fa-youtube" />
                                            </a>
                                            <a href={projectFacebook} title="Facebook" target="_blank">
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                            <a href={projectInstagram} title="Instagram" target="_blank">
                                                <i className="fab fa-instagram" />
                                            </a>
                                            <a href={projectLinkedIn} title="LinkedIn" target="_blank">
                                                <i className="fab fa-linkedin" />
                                            </a>
                                        </div>
                                    </div>
                                </section>
                                <section id="categories-1" className="widget widget_categories">
                                    <h2 className="widget-title">Quick Links</h2>
                                    <ul>
                                        <li className="cat-item cat-item-16">
                                            <Link to="/admission/fees">Fees</Link>
                                        </li>
                                        <li className="cat-item cat-item-23">
                                            <Link to="/news">News</Link>
                                        </li>
                                        <li className="cat-item cat-item-18">
                                            <Link to="/events">Event</Link>
                                        </li>
                                        <li className="cat-item cat-item-22">
                                            <Link to="/gallery">Gallery</Link>
                                        </li>
                                    </ul>
                                </section>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Feeding