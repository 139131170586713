import React, { useEffect, useState }  from 'react'
import Bredcom from "../../../component/Bredcom/Main";
import courseImg1 from '../../../assets/images/course.png';
import { connect } from "react-redux";
import axios from "axios";
import {setCourseListDetails, setGeneralDetails} from "../../../actions/setactiondetails";
import {Link} from "react-router-dom";
import { serverLink } from '../../../resources/constants';
import SpinnerLoader from "../../../component/common/spinner-loader";
function CourseFinder(props) {
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [courseList, setCourseList] = useState(
        props.courseList.filter((e) =>
            e.CourseName.toLowerCase().includes(search.toLowerCase())
        )
    );
    const [courses, setCourses] = useState(props.courseList);

    const getData = async () => {
        await axios
            .get(`${serverLink}academics/course/view/list`)
            .then((result) => {
                const data = result.data;
                setLoading(false);
                setCourseList(data);
                props.setOnCourseDetails(data);
            })
            .catch((error) => {
                console.log("error", error);
            });
    };

    useEffect(() => {
        getData();
    }, [""]);
    useEffect(() => {
        setCourseList(
            props.courseList.filter((e) =>
                e.CourseName.toLowerCase().includes(search.toLowerCase())
            )
        );
    }, [search]);

    const onCourseChange = (e) => {
        const selectedCourse = e.target.value;
        setSearch(selectedCourse);
    };

    const handleOnFacultyClick = (e) => {
        if (e !== ""){
            setCourseList(props.courseList.filter((item) => item.FacultyCode === e));
        }else{
            setCourseList(props.courseList);
        }
    };

    const handleOnApplicationTypeClick = (e) => {
        setCourseList(
            props.courseList.filter((item) => item.ApplicationType === e)
        );
    };

    return (loading ? <SpinnerLoader/> :
            <>
                <Bredcom title="Home" subtitle="Course Finder"/>
                <section className="shop-area pt-120 pb-120 p-relative project-detail" data-animation="fadeInUp animated" data-delay=".2s" >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 order-last">
                                <aside className="sidebar-widget info-column">
                                    <section id="search-3" className="widget widget_search col-md-12 p-0 mb-5"  style={{width: '100%'}}>
                                        <h2 className="widget-title">Search</h2>
                                        <div  className="search-form" >
                                            <label>
                                                <span className="screen-reader-text">Search for:</span>
                                                <input
                                                    type="search"
                                                    className="search-field"
                                                    placeholder="Search Course"
                                                    onChange={onCourseChange}
                                                />
                                            </label>

                                        </div>
                                    </section>
                                    <section id="categories-1" className="widget widget_categories p-0  mb-5">
                                        <h2 className="widget-title">Filter By Faculty</h2>
                                        <ul>
                                            <li className="cat-item cat-item-16" onClick={() =>
                                                handleOnFacultyClick("")
                                            }>
                                                <Link to={"/admission/course-finder"} >
                                                    All Courses ({
                                                    props.courseList.length
                                                })
                                                </Link>
                                            </li>
                                            {props.facultyList.length > 0 &&
                                            props.facultyList.map((faculty, index) => {
                                                return (
                                                    <li className="cat-item cat-item-16"
                                                        key={index}
                                                        onClick={() =>
                                                            handleOnFacultyClick(faculty.FacultyCode)
                                                        }
                                                    >
                                                        <Link to={"/admission/course-finder"}>
                                                            {faculty.FacultyName} (
                                                            {
                                                                props.courseList.filter(
                                                                    (item) => item.FacultyCode === faculty.FacultyCode
                                                                ).length
                                                            }
                                                            )
                                                        </Link>
                                                    </li>
                                                );
                                            })}

                                        </ul>
                                    </section>
                                </aside>
                            </div>
                            <div className="row align-items-center col-md-9">
                                {
                                    courseList.length > 0 ?
                                        courseList.map((item, key) => {
                                            return (<div className="col-lg-4 col-md-6 ">
                                                <div className="courses-item mb-30 hover-zoomin" style={{minWidth: '100%'}}>
                                                    <div className="thumb fix">
                                                        <Link to={`/course/${item.Slug}`}>
                                                            <img src={courseImg1} alt={item.CourseName}  />
                                                        </Link>
                                                    </div>
                                                    <div className="courses-content" style={{minWidth: '100%'}}>
                                                        <div className="cat">
                                                            <i className="fal fa-graduation-cap text-capitalize" /> {item.ApplicationType}
                                                        </div>
                                                        <h5>
                                                            <Link to={`/course/${item.Slug}`}> {item.CourseName}</Link>
                                                        </h5>

                                                        <Link to={`/course/${item.Slug}`} className="readmore">
                                                            Read More <i className="fal fa-long-arrow-right" />
                                                        </Link>
                                                    </div>
                                                    <div className="icon">
                                                        <img src="../../../assets/img/icon/cou-icon.png" alt="img" />
                                                    </div>
                                                </div>
                                            </div>)
                                        })
                                        :
                                        <></>
                                }


                            </div>
                        </div>
                    </div>
                </section>

            </>
    )
}

const mapStateToProps = (state) => {
    return {
        courseList: state.CourseListDetails,
        facultyList: state.FacultyListDetails,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setOnGeneralDetails: (p) => {
            dispatch(setGeneralDetails(p));
        },
        setOnCourseDetails: (p) => {
            dispatch(setCourseListDetails(p));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CourseFinder);