import React from 'react'
import contact from '../../assets/images/contact-icon01.png'
import email from '../../assets/images/contact-icon02.png'
import location from '../../assets/images/contact-icon03.png'
import { projectEmail } from '../../resources/constants'
function Touch() {
  return (
    <>
        <section id="services" className="services-area pt-120 pb-90 fix">
            <div className="container">
                <div className="row">
                <div className="col-lg-12">
                    <div className="section-title text-center mb-50 wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s" >
                    <h5>
                        <i className="fal fa-graduation-cap" /> Contact Info
                    </h5>
                    <h2>Get In Touch</h2>
                    </div>
                </div>
                </div>
                <div className="row">
                <div className="col-lg-4 col-md-4">
                    <div className="services-box text-center">
                    <div className="services-icon">
                        <img src={contact} alt="image" />
                    </div>
                    <div className="services-content2">
                        <h5>
                        <a href="tel:+234 80334789667">+234 803 347 89667</a>
                        </h5>
                        <p>Phone Support</p>
                    </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4">
                    <div className="services-box text-center active">
                    <div className="services-icon">
                        <img src={email} alt="image" />
                    </div>
                    <div className="services-content2">
                        <h5>
                        <a href={projectEmail}>
                            {projectEmail}
                        </a>
                        </h5>
                        <p>Email Address</p>
                    </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4">
                    <div className="services-box text-center">
                    <div className="services-icon">
                        <img src={location} alt="image" />
                    </div>
                    <div className="services-content2">
                        <h5>12/A, Abuja, Nigeria</h5>
                        <p>University Address</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Touch