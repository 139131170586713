import React from 'react'
import { Link } from 'react-router-dom'

function HomeAbout() {
    return (
        <>
            <section className="about-area about-p pt-120 pb-120 p-relative fix" style={{ background: "#eff7ff" }} >
                <div className="animations-02">
                    <img src={require("../../assets/images/bg/an-img-02.png")} alt="contact-bg-an-01" />
                </div>

                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12" >
                            <div className="s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s"  >
                                <img src={require("../../assets/images/COSMOPOLITAN_UNIVERSITY_017.jpg")} alt="img" style={{ borderRadius: "10px" }} />
                                <div className="about-text second-about">
                                    <span>
                                        5 <sub>+</sub>
                                    </span>
                                    <p>Years of Experience</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">
                                    <h5>
                                        <i className="fal fa-graduation-cap" /> About Our University
                                    </h5>
                                    <h2>Cosmopolitan in Brief</h2>
                                </div>
                                <p className="txt-clr" style={{ textAlign: "justify" }}>
                                    Cosmopolitan University is a prestigious institution of higher learning dedicated to providing exceptional education and innovative learning experiences to students from all walks of life. With a strong focus on academic excellence, cutting-edge research, and holistic development, we strive to nurture the next generation of leaders, thinkers, and change-makers.
                                </p>
                                <p>
                                    Welcome to Cosmopolitan University, where knowledge meets innovation, and dreams become reality.

                                </p>
                              
                                <div className="slider-btn mt-20">
                                    <Link to="/about-us" className="btn ss-btn smoth-scroll">
                                        Read More <i className="fal fa-long-arrow-right" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeAbout