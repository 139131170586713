import React, {useState} from "react";
import {connect} from "react-redux";

import {Link, useLocation, useParams} from "react-router-dom";
import {
    formatDateAndTime,
    projectAdmissionURL,
    projectEmail,
    projectPhone,
    projectPublicURL
} from "../../resources/constants";
import Bredcom from "../Bredcom/bredcom";

function FacultyDetail(props) {
    const location = useLocation();
    const { slug } = useParams();

    if (slug === "") {
        window.location.href = "/";
    }
    const faculty_list = props.facultyList.filter(i => i.Slug === slug)
    if (faculty_list.length !== 1) {
        window.location.href = "/";
    }
    const faculty_detail_list = props.facultyDetail.filter(i => i.FacultyCode === faculty_list[0].FacultyCode)
    if (faculty_detail_list.length !== 1) {
        window.location.href = "/";
    }

    const selected_faculty = faculty_list[0];
    const faculty_detail = faculty_detail_list[0];
    const faculty_dean = props.staffList.filter(e=>e.StaffID === selected_faculty.FacultyDean)

    return (
        <>
            <Bredcom
                title="Academics"
                subtitle={selected_faculty.FacultyName}
                bgImage={`${projectPublicURL}faculty_uploads/${faculty_detail.FacultyImage}`}
            />

            <section className="project-detail" style={{marginTop: "10px !important"}}>
                <div className="container">
                    <div className="lower-content">
                        <div className="row">
                            <div className="text-column col-lg-9 col-md-9 col-sm-12">
                                <h2>{selected_faculty.FacultyName}</h2>
                                <div className="inner-column">
                                    <div style={{textAlign: "justify !important"}} dangerouslySetInnerHTML={{__html: faculty_detail.FacultyDetail}} />
                                </div>

                                {
                                    faculty_dean.length > 0 &&
                                    <section className="pt-25">
                                        <h2>Faculty Dean</h2>
                                        <div className="lower-content">
                                            <div className="row">
                                                <div className="col-lg-5 col-md-12 col-sm-12">
                                                    <div className="team-img-box">
                                                        <Link to="/team-single">
                                                            <img src={faculty_dean[0].Image === '' ? '/assets/img/team/team01.jpg' : `${projectPublicURL}hr/document/${faculty_dean[0].Image}`} alt={`${faculty_dean[0].FirstName} ${faculty_dean[0].MiddleName} ${faculty_dean[0].Surname}`} />
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="text-column col-lg-7 col-md-12 col-sm-12">
                                                    <div className="s-about-content pl-30 wow fadeInRight" data-animation="fadeInRight" data-delay=".2s" >
                                                        <h3>{`${faculty_dean[0].TitleName} ${faculty_dean[0].FirstName} ${faculty_dean[0].MiddleName} ${faculty_dean[0].Surname}`}</h3>
                                                        <div dangerouslySetInnerHTML={{__html: `${faculty_dean[0].Biography.substring(0, 200)}...` }} />
                                                        <div className="per-info">
                                                            <div className="info-text">
                                                                <p>
                                                                    <i className="fal fa-phone" /> {faculty_dean[0].PhoneNumber}
                                                                </p>
                                                                <p>
                                                                    <i className="fal fa-envelope" /> {faculty_dean[0].OfficialEmailAddress}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="two-column mt-30">
                                                            <div className="row aling-items-center">
                                                                <div className="image-column col-xl-6 col-lg-12 col-md-12">
                                                                    <div className="footer-social mt-10">
                                                                        <a href={faculty_dean[0].Facebook}>
                                                                            <i className="fab fa-facebook-f" />
                                                                        </a>
                                                                        <a href={faculty_dean[0].Linkedin}>
                                                                            <i className="fab fa-linkedin" />
                                                                        </a>
                                                                        <a href={faculty_dean[0].Researchgate}>
                                                                            <i className="fab fa-researchgate" />
                                                                        </a>
                                                                        <a href={faculty_dean[0].Scholar}>
                                                                            <i className="fab fa-google-plus" />
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <div className="text-column col-xl-6 col-lg-12 col-md-12 text-right">
                                                                    <div className="slider-btn">
                                                                        <Link to={`/staff/${faculty_dean[0].Slug}`} className="btn ss-btn smoth-scroll">
                                                                            Read More <i className="fal fa-long-arrow-right" />
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                }

                                <h2 className="pt-25">Our Programmes</h2>
                                <div className="inner-column">
                                    <div className="row align-items-center class-scroll">
                                            {
                                                props.courseList.filter(e=>e.FacultyCode === selected_faculty.FacultyCode).length > 0 &&
                                                props.courseList.filter(e=>e.FacultyCode === selected_faculty.FacultyCode).map((r,i) => {
                                                    return (
                                                        <div className="col-lg-6 col-md-6 col-sm-12" key={i}>
                                                            <div className="class-item mb-30 w-100">
                                                                <div className="class-content">
                                                                    <h4 className="title"><Link to={`/course/${r.Slug}`}>{r.CourseName}</Link></h4>
                                                                </div>

                                                                <ul className="schedule">
                                                                    <li>
                                                                        <span>Duration:</span>
                                                                        <span className="class-age">{`${r.Duration} ${r.DurationType}`}</span>
                                                                    </li>
                                                                    <li>
                                                                        <span>Department:</span>
                                                                        <span className="class-size">{r.DepartmentName}</span>
                                                                    </li>

                                                                </ul>

                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }


                                    </div>
                                </div>

                                {
                                    props.staffList.filter(e=>e.FacultyCode === selected_faculty.FacultyCode).length > 0 &&
                                    <>
                                        <h2 className="pt-25">Faculty Staff Directory</h2>
                                        <section className="team-area fix p-relative pt-20 pb-20" style={{ background: "#f7f9ff" }} >
                                            <div className="container">
                                                <div className="row">
                                                    {
                                                        props.staffList.filter(e=>e.FacultyCode === selected_faculty.FacultyCode).length > 0 &&
                                                        props.staffList.filter(e=>e.FacultyCode === selected_faculty.FacultyCode).map((r,i) => {
                                                            return (
                                                                <div className="col-xl-4 col-md-6" key={i}>
                                                                    <div className="single-team mb-40">
                                                                        <div className="team-thumb">
                                                                            <div className="brd">
                                                                                <Link to={`/staff/${r.Slug}`}>
                                                                                    {" "}
                                                                                    <img src={r.Image === '' ? '/assets/img/team/team09.png' : `${projectPublicURL}hr/document/${r.Image}`} alt={`${r.FirstName} ${r.MiddleName} ${r.Surname}`} />
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                        <div className="team-info">
                                                                            <h4>
                                                                                <Link to={`/staff/${r.Slug}`}>{`${r.FirstName} ${r.MiddleName} ${r.Surname}`}</Link>
                                                                            </h4>
                                                                            <p>{r.DesignationName}</p>
                                                                            <div className="team-social">
                                                                                <ul>
                                                                                    <li>
                                                                                        <a href={r.Facebook}>
                                                                                            <i className="fab fa-facebook-f" />
                                                                                        </a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a href={r.Researchgate}>
                                                                                            <i className="fab fa-researchgate" />
                                                                                        </a>
                                                                                    </li>
                                                                                    <li>
                                                                                        {" "}
                                                                                        <a href={r.Linkedin}>
                                                                                            <i className="fab fa-linkedin" />
                                                                                        </a>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }


                                                </div>
                                            </div>
                                        </section>
                                    </>
                                }

                                {
                                    props.newsList.filter(e=>e.FacultyCode === selected_faculty.FacultyCode).length > 0 &&
                                    <>
                                        <h2 className="pt-25">Our News</h2>
                                        <div className="row">
                                            {
                                                props.newsList.filter(e=>e.FacultyCode === selected_faculty.FacultyCode).map((r,i) => {
                                                    return (
                                                        <div key={i} className="col-md-6 col-lg-6 bsingle__post mb-50">
                                                            <div className="bsingle__post-thumb">
                                                                <img src={r.ImagePath === '' ? '/assets/img/blog/inner_b1.jpg' : `${projectPublicURL}news_uploads/${r.ImagePath}`} alt={r.Title} />
                                                            </div>
                                                            <div className="bsingle__content">
                                                                <div className="meta-info">
                                                                    <div className="meta-info">
                                                                        <ul>
                                                                            <li>
                                                                                <i className="fal fa-eye" /> {r.ViewCount} Views
                                                                            </li>
                                                                            <li>
                                                                                <i className="fal fa-calendar-alt" /> {formatDateAndTime(r.InsertedDate, 'date_and_time')}
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <h4>
                                                                    <Link to={`/news/${r.Slug}`}>
                                                                        {r.Title}
                                                                    </Link>
                                                                </h4>
                                                                <div dangerouslySetInnerHTML={{__html: `${r.Description.substring(0,150)}...`}} />
                                                                <div className="blog__btn">
                                                                    <Link to={`/news/${r.Slug}`} className="btn">
                                                                        Read More <i className="fal fa-long-arrow-right" />
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>

                                    </>
                                }

                                {
                                    props.eventList.filter(e=>e.FacultyCode === selected_faculty.FacultyCode).length > 0 &&
                                    <>
                                        <h2 className="pt-25">Our Event</h2>
                                        <div className="row">
                                            {
                                                props.eventList.filter(e=>e.FacultyCode === selected_faculty.FacultyCode).map((r,i) => {
                                                    if (i < 3) {
                                                        return (
                                                            <div key={i} className="col-lg-4 col-md-6  wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                                                                <div className="event-item mb-30 hover-zoomin">
                                                                    <div className="thumb">
                                                                        <Link to={`/event/${r.Slug}`}>
                                                                            <img src={r.ImagePath === '' ? '/assets/img/bg/evn-img-1.jpg' : `${projectPublicURL}event_uploads/${r.ImagePath}`} alt={r.Title} />
                                                                        </Link>
                                                                    </div>
                                                                    <div className="event-content">
                                                                        <div className="date">
                                                                            <strong>{formatDateAndTime(r.EventDate, 'day')}</strong> {formatDateAndTime(r.EventDate, 'full_month')}, {formatDateAndTime(r.EventDate, 'year_only')}
                                                                        </div>
                                                                        <h3>
                                                                            <Link to={`/event/${r.Slug}`}>
                                                                                {r.Title}
                                                                            </Link>
                                                                        </h3>
                                                                        <div dangerouslySetInnerHTML={{__html: `${r.Description.substring(0,100)}...`}} />
                                                                        <div className="time">
                                                                            {r.StartTime} - {r.EndTime} <i className="fal fa-long-arrow-right" />{" "}
                                                                            <strong>{r.Location}</strong>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }
                                        </div>

                                    </>
                                }
                            </div>

                            <div className="col-lg-3">
                                <aside className="sidebar-widget info-column">
                                    <div className="inner-column3">
                                        <h3>Faculty Features</h3>
                                        <ul className="project-info clearfix">
                                            <li>
                                                <span className="icon fal fa-user" />{" "}
                                                <strong>Dean:</strong> <span><Link to={`/staff/${selected_faculty.DeanSlug}`}>{selected_faculty.DeanName}</Link></span>
                                            </li>
                                            <li>
                                                <span className="icon fal fa-book" />{" "}
                                                <strong>Programmes:</strong> <span>{props.courseList.filter(e=>e.FacultyCode === selected_faculty.FacultyCode).length
                                                }</span>
                                            </li>
                                            <li>
                                                <span className="icon fal fa-clock" />{" "}
                                                <strong>Code: </strong> <span>{selected_faculty.FacultyCode}</span>
                                            </li>
                                            <li>
                                                <span className="icon fal fa-phone" />{" "}
                                                <strong>Phone: </strong> <span><a href={`tel:${projectPhone}`}>Call Us</a></span>
                                            </li>
                                            <li>
                                                <span className="icon fal fa-envelope" />{" "}
                                                <strong>Email: </strong> <span><a href={`mailto:${projectEmail}`}>Email Us</a></span>
                                            </li>
                                            <li>
                                                <div className="slider-btn">
                                                    <a href={projectAdmissionURL} className="btn ss-btn smoth-scroll">
                                                        Apply Now <i className="fal fa-long-arrow-right" />
                                                    </a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

const mapStateToProps = (state) => {
    return {
        facultyList: state.FacultyListDetails,
        facultyDetail: state.FacultyDataDetails,
        courseList: state.CourseListDetails,
        staffList: state.StaffListDetails,
        newsList: state.NewsDetails,
        eventList: state.EventDetails
    };
};


export default connect(mapStateToProps, null)(FacultyDetail);
