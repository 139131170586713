import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {applyMiddleware, compose, createStore} from "redux";
import rootReducer from "./reducers/rootReducer";
import {createRoot} from "react-dom";
import {persistStore} from "redux-persist";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import { status } from './resources/constants';
import thunkMiddleware from "redux-thunk";
import { logger } from "redux-logger/src";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
let store;
if (status === "Dev") {
    store = createStore(
        rootReducer,
        composeEnhancers(applyMiddleware(logger, thunkMiddleware))
    );
} else {
    store = createStore(
        rootReducer,
        composeEnhancers(applyMiddleware(logger, thunkMiddleware))
    );
}

const persist = persistStore(store);
const root = createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
        <PersistGate persistor={persist}>
            <App />
        </PersistGate>
    </Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
