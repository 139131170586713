import React from 'react'
import { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { currencyConverter } from '../../resources/constants';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button type="button" className={`slick-next ${className}`} style={{ ...style }} onClick={onClick} ><i className="fal fa-angle-right"></i></button>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button type="button" className={`slick-prev ${className}`} style={{ ...style }} onClick={onClick} ><i className="fal fa-angle-left"></i></button>

    );
}



function HomeCourses(props) {
    const settings = {
        dots: false,
        infinite: true,
        arrows: true,
        prevArrow: <SamplePrevArrow />,
        nextArrow: <SampleNextArrow />,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
        ]
    };

    const [courseList, setCourseList] = useState(props.courseList.length > 0 ? props.courseList : []);

    const alphabets = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    const [selected, setSelected] = useState('All');

    const onFilter = (alpha) => {
        setSelected(alpha);
        const filter = props.courseList.length > 0 ?
            props.courseList.filter(x => x.CourseName[0] === alpha).length > 0 ?
                props.courseList.filter(x => x.CourseName[0] === alpha) : []
            : [];
        setCourseList(alpha === 'All' ? props.courseList : filter)
    }
    return (
        <>
            <section className="cta-area cta-bg pt-50 pb-50" style={{ backgroundImage: `url(${require("../../assets/images/banner3_dark.png")})` }} >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="section-title cta-title wow fadeInLeft animated" data-animation="fadeInDown animated" data-delay=".2s" >
                                <h2>Explore Our Programs</h2>
                                <p>
                                    Find your program and begin your an exciting journey with us...
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 text-right">
                            <div className="cta-btn s-cta-btn wow fadeInRight animated mt-30" data-animation="fadeInDown animated" data-delay=".2s" >
                                <Link to="/admission/course-finder" className="btn ss-btn smoth-scroll">
                                    Course Finder <i className="fal fa-long-arrow-right" />
                                </Link>
                            </div>
                        </div>
                        <div className='d-flex flex-wrap'>
                            <button className="btn-alpabets btn-sm"
                                style={{ backgroundColor: selected === "All" ? "#082c3c" : "#a18b5c" }}
                                onClick={() => onFilter('All')}>
                                All
                            </button>
                            {
                                alphabets.length > 0 &&
                                alphabets.map((x, i) => {
                                    return (
                                        <button key={i} className="btn-alpabets btn-sm"
                                            style={{ backgroundColor: x === selected ? "#082c3c" : "#a18b5c" }}
                                            onClick={() => onFilter(x)}>
                                            {x}
                                        </button>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </section>
            <section className="courses pt-50 pb-120 p-relative fix">
                <div className="animations-01">
                    <img src="/assets/img/bg/an-img-03.png" alt="an-img-01" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 p-relative">
                            <div className="section-title center-align mb-50 wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s" >
                                <h5>
                                    <i className="fal fa-graduation-cap" /> Featured Courses
                                </h5>

                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        {
                            courseList.length > 0 ?
                                courseList.slice(0, 3).map((x, i) => {
                                    return (
                                        <div key={i} className="col-md-4">
                                            <div className="courses-item mb-30 hover-zoomin">
                                                {/* <div className="thumb fix">
                                                <Link to={`/admission/course/${x.Slug}`}>
                                                    <img src="assets/img/bg/couress-img-1.jpg" alt="contact-bg-an-01" />
                                                </Link>
                                            </div> */}
                                                <div className="cat">
                                                    <i className="fal fa-graduation-cap" /> {x.FacultyName.replace("Faculty of", "")}
                                                </div>
                                                <div className="courses-content">

                                                    <br />
                                                    <h3>
                                                        <Link to={`/course/${x.Slug}`}> {x.CourseName}</Link>
                                                    </h3>
                                                    <p>
                                                        Class : {x.ApplicationType}({x.Duration + " " + x.DurationType}) <br />
                                                        Tuition Fee : {currencyConverter(x.TuitionFee)}

                                                    </p>
                                                    <Link to={`/course/${x.Slug}`} className="readmore">
                                                        Read More <i className="fal fa-long-arrow-right" />
                                                    </Link>
                                                </div>
                                                <div className="icon">
                                                    <img src="assets/img/icon/cou-icon.png" alt="img" />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <h2>No Featured Course for Letter {selected.toUpperCase()}</h2>
                        }
                    </div>
                    <Slider className="row class-active" {...settings}>


                    </Slider>
                </div>
            </section>
        </>
    )
}


const mapStateToProps = (state) => {
    return {
        courseList: state.CourseListDetails
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        // setOnCourseListDetail: (p) => {
        //     dispatch(setCourseListDetails(p));
        // },
    };
};

export default connect(mapStateToProps, null)(HomeCourses);