import React  from 'react'
import {
    projectFacebook,
    projectInstagram,
    projectLinkedIn,
    projectTwitter,
    projectYoutube
} from "../../resources/constants";
import {Link} from "react-router-dom";

function IndustryEngagementContent() {
    return (
        <>
            <section className="inner-blog pt-120 pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">
                                    <h5>
                                        <i className="fal fa-graduation-cap" /> Industry Engagement at Cosmopolitan University
                                    </h5>
                                    <hr style={{border: '1px solid #cccccc', margin: '4px'}}/>
                                </div>
                                <div style={{textAlign: "justify"}}>
                                    <p>
                                        At Cosmopolitan University, we recognize the vital role of industry engagement in bridging the gap between academia and the real world. We actively collaborate with industry partners to foster innovation, drive economic growth, and ensure the relevance and impact of our academic programs. Through strategic partnerships, collaborative research projects, and experiential learning opportunities, we aim to empower our students with the skills, knowledge, and experiences necessary to succeed in the ever-evolving professional landscape. Here's an overview of our industry engagement initiatives:
                                    </p>
                                    <div className="col-lg-12">
                                        <div
                                            className="faq-wrap mt-30 pr-30 wow fadeInUp animated"
                                            data-animation="fadeInUp"
                                            data-delay=".4s"
                                        >
                                            <div className="accordion" id="accordionExample">
                                                <div className="card">
                                                    <div className="card-header" id="headingThree">
                                                        <h2 className="mb-0">
                                                            <button
                                                                className="faq-btn"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#collapseThree"
                                                            >
                                                                Industry Collaborations
                                                            </button>
                                                        </h2>
                                                    </div>
                                                    <div
                                                        id="collapseThree"
                                                        className="collapse show"
                                                        data-bs-parent="#accordionExample"
                                                    >
                                                        <div className="card-body">
                                                            We establish strong collaborations with leading industry partners across various sectors. These partnerships facilitate knowledge exchange, industry insights, and mutual learning. By working closely with industry professionals, we gain a deep understanding of industry trends, challenges, and emerging technologies, allowing us to continually adapt and update our curriculum to meet industry needs.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" id="headingOne">
                                                        <h2 className="mb-0">
                                                            <button
                                                                className="faq-btn collapsed"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#collapseOne"
                                                            >
                                                                Internship and Work Placement Programs
                                                            </button>
                                                        </h2>
                                                    </div>
                                                    <div
                                                        id="collapseOne"
                                                        className="collapse"
                                                        data-bs-parent="#accordionExample"
                                                    >
                                                        <div className="card-body">
                                                            We provide our students with valuable hands-on experience through internship and work placement programs. These programs enable students to apply their classroom knowledge in real-world settings, gain practical skills, and develop a professional network. Through industry partnerships, we connect our students with reputable organizations, giving them access to meaningful learning opportunities and enhancing their employability.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" id="headingTwo">
                                                        <h2 className="mb-0">
                                                            <button
                                                                className="faq-btn collapsed"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#collapseTwo"
                                                            >
                                                                Research and Development Collaborations
                                                            </button>
                                                        </h2>
                                                    </div>
                                                    <div
                                                        id="collapseTwo"
                                                        className="collapse"
                                                        data-bs-parent="#accordionExample"
                                                    >
                                                        <div className="card-body">
                                                            We actively engage in collaborative research and development projects with industry partners. These partnerships foster innovation, drive technological advancements, and address industry-specific challenges. By combining our academic expertise with industry insights and resources, we create a fertile ground for interdisciplinary research and promote knowledge transfer that benefits both academia and industry.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" id="headingTwo">
                                                        <h2 className="mb-0">
                                                            <button
                                                                className="faq-btn collapsed"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#collapseTwo"
                                                            >
                                                                Professional Development and Continuing Education
                                                            </button>
                                                        </h2>
                                                    </div>
                                                    <div
                                                        id="collapseTwo"
                                                        className="collapse"
                                                        data-bs-parent="#accordionExample"
                                                    >
                                                        <div className="card-body">
                                                            We offer professional development and continuing education programs in collaboration with industry partners. These programs cater to the needs of working professionals seeking to enhance their skills and stay abreast of industry trends. By partnering with industry experts, we ensure that our programs are relevant, practical, and aligned with industry standards.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" id="headingTwo">
                                                        <h2 className="mb-0">
                                                            <button
                                                                className="faq-btn collapsed"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#collapseTwo"
                                                            >
                                                                Entrepreneurship and Innovation
                                                            </button>
                                                        </h2>
                                                    </div>
                                                    <div
                                                        id="collapseTwo"
                                                        className="collapse"
                                                        data-bs-parent="#accordionExample"
                                                    >
                                                        <div className="card-body">
                                                            We actively support entrepreneurship and innovation initiatives by partnering with startups, incubators, and industry-specific innovation hubs. These collaborations foster an entrepreneurial mindset among our students, promote technology transfer, and contribute to economic growth. Through industry engagement, we create an ecosystem that nurtures innovation, supports startups, and encourages the commercialization of research findings.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" id="headingTwo">
                                                        <h2 className="mb-0">
                                                            <button
                                                                className="faq-btn collapsed"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#collapseTwo"
                                                            >
                                                                Industry-focused Events and Conferences
                                                            </button>
                                                        </h2>
                                                    </div>
                                                    <div
                                                        id="collapseTwo"
                                                        className="collapse"
                                                        data-bs-parent="#accordionExample"
                                                    >
                                                        <div className="card-body">
                                                            We organize industry-focused events, conferences, and workshops that bring together academia, industry professionals, and policymakers. These platforms provide opportunities for networking, knowledge sharing, and collaboration. By facilitating meaningful interactions between academia and industry, we foster dialogue, exchange ideas, and promote innovative solutions to industry challenges.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" style={{textAlign: "justify"}}>
                                <p>
                                    At Cosmopolitan University, industry engagement is a cornerstone of our educational philosophy. We believe that by closely collaborating with industry partners, we can prepare our students to excel in their chosen fields and contribute to the growth and development of industries. We welcome industry collaboration opportunities and invite organizations to join us in shaping the future of education, research, and industry.
                                </p>
                                <p>
                                    Partner with us and be part of a dynamic community dedicated to advancing knowledge, driving innovation, and shaping the future of industries. Together, we can build meaningful partnerships that benefit both academia and industry.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-4">
                            <aside className="sidebar-widget">
                                <section id="custom_html-5" className="widget_text widget widget_custom_html" >
                                    <h2 className="widget-title">Follow Us</h2>
                                    <div className="textwidget custom-html-widget">
                                        <div className="widget-social">
                                            <a href={projectTwitter} title="Twitter" target="_blank">
                                                <i className="fab fa-twitter" />
                                            </a>
                                            <a href={projectYoutube} title="Youtube" target="_blank">
                                                <i className="fab fa-youtube" />
                                            </a>
                                            <a href={projectFacebook} title="Facebook" target="_blank">
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                            <a href={projectInstagram} title="Instagram" target="_blank">
                                                <i className="fab fa-instagram" />
                                            </a>
                                            <a href={projectLinkedIn} title="LinkedIn" target="_blank">
                                                <i className="fab fa-linkedin" />
                                            </a>
                                        </div>
                                    </div>
                                </section>
                                <section id="categories-1" className="widget widget_categories p-3  mb-5 mt-5">
                                    <h2 className="widget-title">Quick Links</h2>
                                    <ul>
                                        <li className="cat-item cat-item-16">
                                            <Link to="/admission/fees">Fees</Link>
                                        </li>
                                        <hr style={{border: '1px solid #cccccc', margin: '4px'}}/>
                                        <li className="cat-item cat-item-23">
                                            <Link to="/news">News</Link>
                                        </li>
                                        <hr style={{border: '1px solid #cccccc', margin: '4px'}}/>
                                        <li className="cat-item cat-item-18">
                                            <Link to="/events">Event</Link>
                                        </li>
                                        <hr style={{border: '1px solid #cccccc', margin: '4px'}}/>
                                        <li className="cat-item cat-item-22">
                                            <Link to="/gallery">Gallery</Link>
                                        </li>
                                    </ul>
                                </section>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default IndustryEngagementContent