import React from 'react'
import Bredcom from '../../pages/Bredcom/bredcom'
import PartnershipContent from "./partnership-content";

function Partnership() {
    return (
        <>
            <Bredcom title="Home"  subtitle="Partnership"/>
            <PartnershipContent/>
        </>
    )
}

export default Partnership