import React from 'react'
import image1 from '../../assets/images/COSMOPOLITAN UNIVERSITY 085.jpg'
import image2 from '../../assets/images/COSMOPOLITAN UNIVERSITY 068.jpg'

function SportContent() {
    return (
        <>
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s" >
                                <img src={image1} alt="img" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">
                                    <h5>
                                        <i className="fal fa-graduation-cap" /> Sports and Recreation
                                    </h5>
                                </div>
                                <div style={{textAlign: "justify"}}>
                                    <p>
                                        At Cosmopolitan University, we recognize the importance of a well-rounded education that goes beyond academics. We believe in the power of sports and recreation to foster physical fitness, mental well-being, teamwork, and personal growth. Our Sports and Recreation program offers a wide range of activities and facilities designed to cater to diverse interests and abilities. Whether you are an aspiring athlete, a fitness enthusiast, or someone looking to have fun and unwind, we have something for everyone. Here's a glimpse of what our Sports and Recreation program has to offer:
                                    </p>
                                    <p>
                                        <strong>State-of-the-Art Facilities: </strong>
                                        Our campus boasts modern and well-equipped sports facilities to support a variety of athletic pursuits. We have spacious indoor and outdoor sports fields, courts for basketball, volleyball, and tennis, a fully-equipped gymnasium, and dedicated spaces for yoga and group fitness classes. Our facilities are designed to provide a safe and conducive environment for students to engage in their chosen sports and recreational activities.
                                    </p>
                                    <p>
                                        <strong>Competitive Sports: </strong>
                                        For those interested in competitive sports, we offer a wide range of intercollegiate sports teams that compete in various leagues and tournaments. Whether you have a passion for football, basketball, volleyball, or other sports, you can represent Cosmopolitan University and showcase your skills on the field. Our experienced coaches and trainers provide guidance and support to help you reach your full potential.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row col-md-12 col-sm-12 about-content s-about-content pl-15 wow fadeInRight  animated" style={{textAlign: "justify"}}>
                            <div className="col-lg-6">
                                <div className="s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s" >
                                    <img src={image2} alt="img" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <p>
                                    <strong>Recreational Activities: </strong>
                                    At Cosmopolitan University, we believe that recreation is an integral part of a student's life. We provide opportunities for students to engage in recreational activities that promote relaxation, socialization, and personal enjoyment. Whether it's participating in casual sports games with friends, organizing outdoor excursions, or joining recreational clubs, there are numerous avenues to unwind, have fun, and create lasting memories.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-12 mt-20 about-content s-about-content pl-15 wow fadeInRight text-center  animated" style={{textAlign: "justify"}}>
                            <p>At Cosmopolitan University, we believe that sports and recreation play a vital role in nurturing well-rounded individuals. Our Sports and Recreation program aims to create a supportive and inclusive environment where students can thrive physically, mentally, and socially. We encourage all students to explore the wide range of sports, fitness, and recreational activities available and make the most of their university experience.
                            </p>
                            <p>Come and join us at Cosmopolitan University, where we celebrate the power of sports and recreation in shaping a healthy, vibrant, and balanced campus community.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SportContent;

