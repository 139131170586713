const CryptoJS = require("crypto-js");
export const status = "Live";
export const serverStatus = status;
export const serverLink = status === "Dev" ? "http://localhost:4480/" : "https://smartsourcingapi.com:5005/";
export const projectPublicURL = `${serverLink}public/uploads/CU/`;
export const projectName = "Cosmopolitan University";
export const projectCode = "COSMOPOLITAN_UNIVERSITY_WEBSITE";
export const projectURL = "https://cosmopolitanuniversity.edu.ng";
export const projectAdmissionSystem = "https://admission.cosmopolitan.smartsourcing.ng/";
export const projectPhone = "+234 803 347 89667";
export const projectAdmissionURL = "https://admission.cosmopolitan.smartsourcing.ng";
export const projectEmail = "info@cosmopolitanuniversity.edu.ng";
export const projectAddress = "Central Area, \n Abuja, Nigeria";
export const projectLogo = require('../../src/assets/images/logo-no-bg.png');
export const projectFacebook = "https://www.facebook.com/";
export const projectTwitter = "https://www.twitter.com/";
export const projectYoutube = "https://www.youtube.com/";
export const projectInstagram = "https://instagram.com/?lang=en";
export const projectLinkedIn = "https://linkedin.com/?lang=en";
export const shortCode = "CU"
export const googleMapAdress = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3971.3599264252794!2d7.4864687758753945!3d5.513477434074008!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1042c32cef0f3c95%3A0xafff045c228fae54!2sMgboko%20Isiama%20Afaraukwu%20Community!5e0!3m2!1sen!2sng!4v1685553722916!5m2!1sen!2sng"
export const defImage = require('../../src/assets/images/logo-no-bg.png')

export const Logo = require('../../src/assets/images/logo-no-bg.png')
export const LogoWhite = require('../../src/assets/images/white-logo.png')


export const formatDateAndTime = (date, option) => {
  if (date !== null) {
    const user_date = new Date(date);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthNamesShort = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day =
      user_date.getDate() < 10
        ? "0" + user_date.getDate()
        : user_date.getDate();
    const hour =
      user_date.getHours() < 10
        ? "0" + user_date.getHours()
        : user_date.getHours();
    const min =
      user_date.getMinutes() < 10
        ? "0" + user_date.getMinutes()
        : user_date.getMinutes();
    const sec =
      user_date.getSeconds() < 10
        ? "0" + user_date.getSeconds()
        : user_date.getSeconds();

    let date_string = "";
    if (option === "date_and_time")
      date_string = `${day}-${monthNames[user_date.getMonth()]
        }-${user_date.getFullYear()} : ${hour}:${min}:${sec}`;
    else if (option === "date")
      date_string = `${day}-${monthNames[user_date.getMonth()]
        }-${user_date.getFullYear()}`;
    else if (option === "day") date_string = day;
    else if (option === "full_month")
      date_string = monthNames[user_date.getMonth()];
    else if (option === "short_month")
      date_string = monthNamesShort[user_date.getMonth()];
    else if (option === "year_only") date_string = user_date.getFullYear();
    else if (option === "month_and_year")
      date_string = `${monthNamesShort[user_date.getMonth()]} - ${user_date.getFullYear()}`;

    return date_string;
  } else {
    return "--";
  }
};
export const formatDate = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

export const Ordinal = function (d) {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1: return "st";
    case 2: return "nd";
    case 3: return "rd";
    default: return "th";
  }
}


export const currencyConverter = (amount) => {
  const formatter = new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN',
  });
  return formatter.format(amount)
}

export function encryptData(string, val = false) {
  let secret_key = val === false ? "SayyoFilms" : projectCode;
  let secret_iv = val === false ? "FilmsInternational" : projectCode;
  // hash
  let kee = CryptoJS.SHA256(secret_key);
  let ivv = CryptoJS.SHA256(secret_iv).toString().substr(0, 16);

  kee = CryptoJS.enc.Utf8.parse(kee.toString().substr(0, 32));
  ivv = CryptoJS.enc.Utf8.parse(ivv);

  let decrypted = CryptoJS.AES.encrypt(string, kee, { iv: ivv });

  let result = decrypted.toString();
  return btoa(result);
}

export function decryptData(string, val = false) {
  let secret_key = val === false ? "SayyoFilms" : projectCode;
  let secret_iv = val === false ? "FilmsInternational" : projectCode;
  // hash
  let kee = CryptoJS.SHA256(secret_key);
  let ivv = CryptoJS.SHA256(secret_iv).toString().substr(0, 16);

  kee = CryptoJS.enc.Utf8.parse(kee.toString().substr(0, 32));
  ivv = CryptoJS.enc.Utf8.parse(ivv);

  var decrypted = CryptoJS.AES.decrypt(atob(string), kee, { iv: ivv });

  return decrypted.toString(CryptoJS.enc.Utf8);
}

export function dynamicSort(property) {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
    return result * sortOrder;
  }
}


export function descDynamicSort(property) {
  var sortOrder = -1;
  if (property[0] === "-") {
    sortOrder = 1;
    property = property.substr(1);
  }
  return function (a, b) {
    var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
    return result * sortOrder;
  }
}

export function dynamicDateSort(property) {
  var sortOrder = -1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    var result = (new Date(a[property]) < new Date(b[property])) ? -1 : (new Date(a[property]) > new Date(b[property])) ? 1 : 0;
    return result * sortOrder;
  }
}

const date = new Date();
let day = date.getDate();
let month = date.getMonth() + 1;
let year = date.getFullYear();
export const currentDate = `${year}-${month}-${day}`;
export const FullcurrentDate = `${year}-${month}-${day} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;



export function convertTime(time) {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (time.length > 1) { // If time format correct
    time = time.slice(1);  // Remove full string match value
    time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(''); // return adjusted time or original string
}

const checkImageURL = (url) => {
  if (!url) return false
  else {
    const pattern = new RegExp('^https?:\\/\\/.+\\.(png|jpg|jpeg|bmp|gif|webp)$', 'i');
    return pattern.test(url);
  }
};

export const ImageUrlFormatter = (file_name, path, DefBanner = defImage) => {
  if (file_name === null || file_name === "" || file_name === undefined || file_name === "undefined") {
    return DefBanner;
  } else if (file_name.includes("simplefileupload")) {
    return checkImageURL(file_name) ? file_name : DefBanner
  }
  else {
    return `${checkImageURL(`${serverLink}public/uploads/${shortCode}/${path}/${file_name}`) ? `${serverLink}public/uploads/${shortCode}/${path}/${file_name}` : DefBanner} `
  }
}
