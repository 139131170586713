import React, { useEffect, useState }  from 'react'
import Bredcom from "../../../component/Bredcom/Main";
import {Link} from "react-router-dom";
import axios from "axios";
import { serverLink, shortCode } from "../../../resources/constants";
import LeadershipModal from "../modal/modal";
import SpinnerLoader from "../../../component/common/spinner-loader";
function Senate(props) {
    const [senates, setSenates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [det_, setDet] = useState({
        Name: "",
        Image: "",
        Description: "",
        IsOpen: false
    })

    useEffect(() => {
        async function getBoardMembers() {
            const result = await axios.get(`${serverLink}leadership/senate/list`);
            setSenates(result.data);
            setLoading(false);
        }
        getBoardMembers();
    }, []);

    const canvasMenuAdd = (item) => {
        setDet({
            ...det_,
            Name: `${item.Title} ${item.FullName}`,
            Image: `${serverLink}public/uploads/${shortCode}/leaderships_uploads/${item.ImagePath}`,
            IsOpen: true,
            Description: <p dangerouslySetInnerHTML={{ __html: item.Description }} />
        })
    };

    const openModal = () => {
        const modalContainer = document.getElementById('modalContainer');
        modalContainer.style.display = 'block';
    }

    return (loading ? <SpinnerLoader/> :
            <>
                <Bredcom title="Home" subtitle="Senate"/>
                <section className="team-area2 fix p-relative pt-120 pb-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 p-relative">
                                <div className="section-title center-align mb-50 text-center wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s" >
                                    <h5>
                                        <i className="fal fa-user" /> Senate
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {
                                senates.map((item, index) =>
                                    (
                                        <div key={index} className={`text-center col-xl-3 col-md-6`}>
                                            <div className="single-team mb-40">
                                                <div className="team-thumb">
                                                    <div className="brd">
                                                        <Link  onClick={() => { canvasMenuAdd(item); openModal();} }>
                                                            <img src={`${serverLink}public/uploads/${shortCode}/leaderships_uploads/${item.ImagePath}`}
                                                                 alt={item.FullName}
                                                                 className="img-thumbnail img-fluid"
                                                                 style={{
                                                                     minHeight: '350px',
                                                                     minWidth: '100%',
                                                                     height: '350px',
                                                                     width: '100%',
                                                                 }} />
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="team-info">
                                                    <h5>
                                                        <Link  onClick={() => { canvasMenuAdd(item); openModal();} }>{item.Title} {item.FullName}</Link>
                                                    </h5>
                                                    <p>{item.Designation}</p>
                                                    <p style={{ cursor: 'pointer' }} className="text-center mb-5"
                                                       data-toggle="modal" data-target=".bd-example-modal-lg"
                                                       onClick={() => { canvasMenuAdd(item); openModal();} }
                                                    >View profile</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )
                            }

                        </div>
                    </div>
                    <LeadershipModal
                        IsOpen={det_.IsOpen}
                        Name={det_.Name}
                        Image={det_.Image}
                        Description={det_.Description}
                    />
                </section>

            </>
    )
}

export default Senate