import React, { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom';
import Hometwo from '../component/hometwo/Main'
import Homethree from '../component/homethree/Main'
import About from '../component/About/Main'
import Courses from '../component/Courses/Main'
import Coursestwo from '../component/coursestwo/Main'
import Coursesdeatils from '../component/coursedetails/Main'
import Coursesdeatilstwo from '../component/coursedetailstwo/Main'
import Event from '../component/Pages/event/Main'
import Eventdeatils from '../component/Pages/eventdeatils/Main'
import Gallery from '../component/Pages/gallery/Main'
import Pricing from '../component/Pages/Pricing/Main'
import Faq from '../component/Pages/Faq/Main'
import Teacher from '../component/Pages/Teacher/Main'
import Teacherdeatils from '../component/Pages/teacherdetails/Main'
import Error from '../component/Pages/error/Main'
import Blog from '../component/Blog/Main'
import Blogdeatils from '../component/Blogdeatils/Main'
import Contact from '../component/contact/Main'
import Projectdeatils from '../component/Pages/Projectdeatils/Main'
import Home from '../component/home/Main';
import BoardMembers from "../pages/about/board-members/board-members";
import Management from "../pages/about/management/management";
import Senate from "../pages/about/senete/senate";
import GalleryView from "../pages/about/gallery/gallery";
import CourseFinder from "../pages/admission/course-finder/course-finder";
import LandingPage from '../pages/home/landing';
import AboutUs from "../pages/about-us/about-us";
import ContactUS from "../pages/contact-us/contact-us";
import InternationalStudent from "../pages/international-student/international-student";
import Fees from "../pages/fees/fees";
import Tuition from "../pages/fees/tuition";
import Feeding from "../pages/fees/feeding";
import Accommodation from "../pages/fees/accommodation";
import CourseDetails from "../pages/admission/course-finder/course-details";
import Events from '../pages/events/events';
import FacultyDetail from "../pages/academic/faculty";
import LifeOnCampus from "../pages/life-on-campus/life-on-campus";
import EventsDetails from '../pages/events/event-details';
import TalkToOurStudent from "../pages/talk-to-our-student/talk-to-our-student";
import Sport from "../pages/sport/sport";
import ResearchArea from "../pages/research-area/research-area";
import ResearchGroup from "../pages/research-group-and-centre/research-group-and-centre";
import ResearchCulture from "../pages/research-culture/research-culture";
import ResearchSeminar from "../pages/research-seminar/research-seminar";
import Partnership from "../pages/partnership/partnership";
import CommunityEngagement from "../pages/community-engagement/community-engagement";
import IndustryEngagement from "../pages/industry-engagement/industry-engagement";
import CareerEmployment from "../pages/career-employment/career-employment";
import NewsPage from '../pages/news/news';
import StaffList from '../pages/staff/staff-list';
import StaffDetails from '../pages/staff/staff-details';
import ImpactStory from "../pages/innovation/impact-story";
import Search from "../pages/search/search";
import MakerSpace from "../pages/maker-space/maker-space";
import TechnologyLicensingOffice from "../pages/technology-licensing-office/technology-licensing-office";
import IncubationAcceleration from "../pages/incubation-and-accelaration-lab/incubation";

function Index() {
  const location = useLocation();
  const path = location.pathname
  useEffect(() => {
    window.scroll(0, 0)
  }, [path]);

  return (
    <>

      <Routes>
        <Route path="home/" element={<Home />} />
        <Route path="/home-two" element={<Hometwo />} />
        <Route path="/home-three" element={<Homethree />} />
        <Route path="/about" element={<About />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/courses-2" element={<Coursestwo />} />
        <Route path="/single-courses" element={<Coursesdeatils />} />
        <Route path="/single-courses-2" element={<Coursesdeatilstwo />} />
        <Route path="/event" element={<Event />} />
        <Route path="/single-event" element={<Eventdeatils />} />
        <Route path="/projects" element={<Gallery />} />
        <Route path="/single-projects" element={<Projectdeatils />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/team" element={<Teacher />} />
        <Route path="/team-single" element={<Teacherdeatils />} />
        <Route path="/404-error" element={<Error />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog-details" element={<Blogdeatils />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/search/:param" element={<Search />} />

        {/* //////////////// NEW ROUTES //////////////// */}

        <Route path="/" element={<LandingPage />} />

        {/*ABOUT*/}
        <Route path="/about/board-members" element={<BoardMembers />} />
        <Route path="/about/management" element={<Management />} />
        <Route path="/about/senate" element={<Senate />} />
        <Route path="/about/gallery" element={<GalleryView />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/admission/fees" element={<Fees />} />
        <Route path="/fees/tuition" element={<Tuition />} />
        <Route path="/fees/feeding" element={<Feeding />} />
        <Route path="/fees/accommodation" element={<Accommodation />} />
        <Route path="/about/contact-us" element={<ContactUS />} />
        <Route path="/admission/international-students" element={<InternationalStudent />} />
        <Route path="/student-life/life-on-campus" element={<LifeOnCampus />} />
        <Route path="/student-life/talk-to-our-students" element={<TalkToOurStudent />} />
        <Route path="/student-life/sport-and-recreation" element={<Sport />} />
        <Route path="/research/research-area" element={<ResearchArea />} />
        <Route path="/research/research-group-and-center" element={<ResearchGroup />} />
        <Route path="/research/research-culture" element={<ResearchCulture />} />
        <Route path="/research/research-seminar" element={<ResearchSeminar />} />
        <Route path="/partnership" element={<Partnership />} />
        <Route path="/community-engagement" element={<CommunityEngagement />} />
        <Route path="/industry-engagement" element={<IndustryEngagement />} />
        <Route path="/career-and-employment" element={<CareerEmployment />} />

        {/* ADMISSION */}
        <Route path="/admission/course-finder" element={<CourseFinder />} />
        <Route path="/admission/course/:slug" element={<CourseDetails />} />
        <Route path="/admission/course-finder" element={<CourseFinder />} />
        <Route path="/course/:slug" element={<CourseDetails />} />
        <Route path="/admission/fees" element={<Fees/>} />
        <Route path="/admission/application" element={""} />
        <Route path="/admission/international-students" element={<InternationalStudent/>} />

        {/* ACADEMICS */}
        <Route path="/faculty/:slug" element={<FacultyDetail />} />


        {/* STUDENT LIFE */}
        <Route path="/student-life/accommodation" element={<Accommodation/>} />
        <Route path="/student-life/life-on-campus" element={<LifeOnCampus/>} />
        <Route path="/student-life/talk-to-our-students" element={<TalkToOurStudent/>} />
        <Route path="/student-life/international-students" element={<InternationalStudent/>} />

        {/* RESEARCH */}
        <Route path="/research/research-area" element={<ResearchArea/>} />
        <Route path="/research/research-group-and-center" element={<ResearchGroup/>} />
        <Route path="/research/research-culture" element={<ResearchCulture/>} />
        <Route path="/research/research-seminar" element={<ResearchSeminar/>} />

        {/* COLLABORATION */}
        <Route path="/news" element={<NewsPage />} />
        <Route path="/news/:slug" element={<EventsDetails />} isNews={true} />
        <Route path="/events" element={<Events />} />
        <Route path="/event/:slug" element={<EventsDetails isNews={false} />} />
        <Route path="/career" element={""} />
        <Route path="/partnership" element={<Partnership/>} />
        <Route path="/community-engagement" element={<CommunityEngagement/>} />
        <Route path="/industry-engagement" element={<IndustryEngagement/>} />
        <Route path="/career-and-employment" element={<CareerEmployment/>} />
        <Route path="/our-staff" element={<StaffList />} />
        <Route path="/staff/:slug" element={<StaffDetails />} />

        {/* INNOVATION */}
        <Route path="/innovation/maker-space" element={<MakerSpace/>} />
        <Route path="/innovation/technology-licensing-office" element={<TechnologyLicensingOffice/>} />
        <Route path="/innovation/incubation-and-acceleration-lab" element={<IncubationAcceleration/>} />
        <Route path="/innovation/impact-story" element={<ImpactStory />} />

        {/* SERVICES */}
        {/* PORTAL ROUTES */}



        <Route path="*" element={<Error />} />
      </Routes>
    </>
  )
}

export default Index
