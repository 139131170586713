import React from 'react'
import Bredcom from '../../pages/Bredcom/bredcom'
import CommunityEngagementContent from "./community-engagement-content";

function CommunityEngagement() {
    return (
        <>
            <Bredcom title="Home"  subtitle="Community Engagement"/>
            <CommunityEngagementContent/>
        </>
    )
}

export default CommunityEngagement