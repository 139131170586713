import React from 'react'
import  int_student from '../../assets/images/int.jpg'
function First() {
    return (
        <>
            <section className="event event03 pt-150 pb-120 p-relative fix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-5  wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                            <img src={int_student} alt="circle_right" />
                        </div>

                        <div className="col-lg-7 col-md-7  wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s">
                            <h3>Welcome International Students to Cosmopolitan University! </h3>
                            <p className="txt-clr" style={{textAlign: "justify"}}>
                                At Cosmopolitan University, we embrace diversity and actively welcome students from around the world. We understand that studying abroad is a transformative experience, and we are dedicated to providing international students with a supportive and enriching educational journey.
                            </p>
                            <p>Why Choose Cosmopolitan University?</p>
                            <ul style={{textAlign: "justify"}}>
                                <li>1. Global Learning Environment: As an international student at Cosmopolitan University, you will become part of a vibrant and multicultural community. Interact with students from different backgrounds, cultures, and perspectives, fostering a truly global learning environment.
                                </li>
                                <li>2. World-Class Faculty: Our university boasts an exceptional faculty comprising accomplished educators and professionals from around the globe. Benefit from their expertise, mentorship, and international perspectives as they guide you through your academic journey.
                                </li>
                                <li>3. Innovative Programs: We offer a wide range of innovative programs that are designed to prepare students for success in today's globalized world. Whether you are interested in business, technology, healthcare, or social sciences, our programs are tailored to meet the demands of various industries.
                                </li>
                                <li>4. Technology-Driven Learning: At Cosmopolitan University, we leverage technology to enhance the learning experience. Our state-of-the-art facilities and resources provide you with access to cutting-edge tools and platforms, ensuring you stay at the forefront of digital advancemen</li>
                                <li>5. Global Alumni Network: As a graduate of Cosmopolitan University, you will join a strong and influential global alumni network. Benefit from the connections and opportunities that come with being part of this esteemed community, opening doors for future collaborations and career advancement.</li>
                            </ul>
                            <p>
                                Join us at Cosmopolitan University and embark on an educational journey that will broaden your horizons, shape your future, and empower you to make a positive impact on the world. We look forward to welcoming you to our cosmopolitan family!
                                For more information on admissions, application processes, and student life, please explore our website or contact our dedicated international student services team.
                            </p>
                        </div>

                    </div>
                </div>
            </section>

        </>
    )
}

export default First