import React from 'react'
import Bredcom from '../../pages/Bredcom/bredcom'
import IndustryEngagementContent from "./industry-engagement-content";

function IndustryEngagement() {
    return (
        <>
            <Bredcom title="Home"  subtitle="Industry Engagement"/>
            <IndustryEngagementContent/>
        </>
    )
}

export default IndustryEngagement