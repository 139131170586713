import React from 'react'

function Frequently() {
    return (
        <>
                        <div className="col-lg-12">
                            <div
                                className="faq-wrap mt-30 pr-30 wow fadeInUp animated"
                                data-animation="fadeInUp"
                                data-delay=".4s"
                            >
                                <div className="accordion" id="accordionExample">
                                    <div className="card">
                                        <div className="card-header" id="headingThree">
                                            <h2 className="mb-0">
                                                <button
                                                    className="faq-btn"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseThree"
                                                >
                                                   Our Mission
                                                </button>
                                            </h2>
                                        </div>
                                        <div
                                            id="collapseThree"
                                            className="collapse show"
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="card-body">
                                                Mission
                                                To expand knowledge through research, integrated with education and active engagement with industry and society.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <h2 className="mb-0">
                                                <button
                                                    className="faq-btn collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseOne"
                                                >
                                                    Our Vision
                                                </button>
                                            </h2>
                                        </div>
                                        <div
                                            id="collapseOne"
                                            className="collapse"
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="card-body">
                                                To be a student-centred university offering applied and innovative experimental programs to prepare graduates to make a positive impact in their respective workplaces, environment, and communities.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingTwo">
                                            <h2 className="mb-0">
                                                <button
                                                    className="faq-btn collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseTwo"
                                                >
                                                    Our Promise
                                                </button>
                                            </h2>
                                        </div>
                                        <div
                                            id="collapseTwo"
                                            className="collapse"
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="card-body">
                                                <ul>
                                                    <li>Technology-driven learning</li>
                                                    <li>Research-driven innovations</li>
                                                    <li>Embracing AI but with appropriate regulations</li>
                                                    <li>International Faculty teaching from all over the world</li>
                                                    <li>Prudent financial management</li>
                                                    <li>Reward system for staff that will Measure and match performance with output</li>
                                                    <li>Unparalleled welfare and learning experience for students</li>
                                                    <li>Robust Academic Governance</li>
                                                    <li>Equality of opportunity for all stakeholders</li>
                                                    <li>Community Development</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

        </>
    )
}

export default Frequently